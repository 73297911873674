import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import RefConditionsWrapper from "./RefConditionsWrapper.vue";

import RefEquals from "./conditions/RefEquals";
import RefLower from "./conditions/RefLower";
import RefUpper from "./conditions/RefUpper";

import store from "@/store/store";
import { ULTIMATE_TAG } from "@/includes/constants";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': RefConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.RefConditionsWrapper, 'trigger_condition_'),
  structure: RefEquals.structure,
  extraProps: {
    conditions: [
      RefEquals,
      RefLower,
      RefUpper,
    ],
  },
  limited: () => store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG
}

export default condition
