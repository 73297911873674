


























import { ActionSetupView } from '@/components/TriggerSetup/logic/mixins/action-setup-view.mixin'
import { InputSetups } from '@/mixins/input-setups'

import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue';
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types';
import { FileType } from 'piramis-base-components/src/components/File/types';
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  data() {
    return {
      MediaEditorMode,
      FileType,
    }
  },
  components: {
    MultiMessageEditorWithMediaInput
  }
})
export default class ActionText extends Mixins(ActionSetupView, InputSetups, ModelSetter) {
  @Prop() titlePrefix!:string

  get topics() {
    return {
      'settingsStructure': this.defaultMessageEditorSettingsStructure,
      'options': {
        topics: this.topicsAvailable
      },
      ...this.topicsIfForum
    }
  }
}
