import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import store from "@/store/store";
import { DeleteMessageDelayEnum } from "./types";
import { ADVANCED_TAG } from "@/includes/constants";
import ScheduleDeleteMessageActionSetupView from "./ScheduleDeleteMessageActionSetupView.vue";

const action: EntityData = {
  'setupView': ScheduleDeleteMessageActionSetupView,
  'structure': {
    'type': ActionsType.ScheduleDeleteMessageAction,
    'target': "Caller",
    'time': '12:00',
    'delay': DeleteMessageDelayEnum.Near,
    'chance': 100,
  },
  ...entityDataTitleKeyFields(ActionsType.ScheduleDeleteMessageAction, 'trigger_action_'),
  limited: () => store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.isChatAtLeastAdvanced) ? ADVANCED_TAG : null
}

export default action
