import { render, staticRenderFns } from "./DeleteAllMessageActionSetupView.vue?vue&type=template&id=45d7b8f6&scoped=true&"
import script from "./DeleteAllMessageActionSetupView.vue?vue&type=script&lang=ts&"
export * from "./DeleteAllMessageActionSetupView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d7b8f6",
  null
  
)

export default component.exports