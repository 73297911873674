















































import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import ActionText from '@/components/TriggerSetup/components/ActionText/ActionText.vue'
import { InputSetups } from '@/mixins/input-setups'
import Placeholders from "@/mixins/placeholders/placeholders";

import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types';
import MultiMessageEditorWithMediaInput from "piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue";
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ActionText,
    SelectInput,
    MultiMessageEditorWithMediaInput
  },
  data() {
    return {
      MediaEditorMode
    }
  }
})
export default class SendMessageToCalculatedTargetActionView extends Mixins(ActionSetupView, Placeholders, InputSetups, ModelSetter) {
  async actonCalculatedTarget() {
    return [
      {
        label: this.$t("send_message_to_calculated_target_action_actor"),
        value: "Actor",
      }, {
        label: this.$t("send_message_to_calculated_target_action_target"),
        value: "Target",
      },
      {
        label: this.$t("send_message_to_calculated_target_action_parent_actor"),
        value: "ActorParent",
      }, {
        label: this.$t("send_message_to_calculated_target_action_parent_target"),
        value: "TargetParent",
      }
    ];
  }
}
