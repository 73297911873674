




























import ActionTargets from "@/components/TriggerSetup/components/ActionTargets/ActionTargets.vue";
import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";

import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ActionTargets,
  },
  data() {
    return {
      StepUnit
    }
  }
})
export default class DeleteAllMessageActionSetupView extends Mixins<ActionSetupView>(ActionSetupView) {

}
