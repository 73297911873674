var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"check-boost-setup-view"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.conditionInputSetupFn,
      'args': {
        'model': this,
        'key': 'boostType',
        'prefix': 'trigger_condition_check_boost_',
        'validation': 'required',
        'options': _vm.checkBoostConditionOptions,
        'clearable': false,
        disabled: _vm.disabled,
      }
    }}}),(_vm.boostType === _vm.CheckBoostTargetEnum.FrontendCustom
      || (_vm.boostTarget !== _vm.CheckBoostTargetEnum.Group && _vm.boostTarget !== _vm.CheckBoostTargetEnum.LinkedChannel)
    )?_c('nested-content',[_c('trigger-action-bind-target',{model:{value:(_vm.boostTarget),callback:function ($$v) {_vm.boostTarget=$$v},expression:"boostTarget"}})],1):_vm._e(),_c('condition-invert-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.conditionData),callback:function ($$v) {_vm.conditionData=$$v},expression:"conditionData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }