























import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'

import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ConditionInvertSettings
  }
})
export default class MessageForwardTypeConditionSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {
  get messageForwardTypeOptions(): Array<SelectOptionData> {
    return [
      {
        label: this.$t("trigger_condition_message_forward_type_bot_via").toString(),
        value: 'BotVia'
      },
      {
        label: this.$t("trigger_condition_message_forward_type_user_forward").toString(),
        value: 'UserForward'
      },
      {
        label: this.$t("trigger_condition_message_forward_type_channel_forward").toString(),
        value: 'ChannelForward'
      },
      {
        label: this.$t("trigger_condition_message_forward_type_group_forward").toString(),
        value: 'GroupForward'
      },
      {
        label: this.$t("trigger_condition_message_forward_type_any").toString(),
        value: 'Any'
      },
    ]
  }
}
