var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"schedule-delete-message-action-setup-view"},[_c('action-targets',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('time-picker-input',{staticClass:"mb-0",attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        'model': _vm.model,
        'key': 'time',
        'prefix': 'schedule_delete_message_action_',
        'validation': 'required',
        'disabled': _vm.disabled,
        'time24hr': true,
        'clearable': false
      }
    }}}),_c('timezone-select'),_c('select-input',{staticClass:"w-full",attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        'model': _vm.model,
        'key': 'delay',
        'prefix': 'schedule_delete_message_action_',
        'options': _vm.delayOptions,
        'multiple': false,
        'disabled': _vm.disabled,
        'clearable': false
      },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }