import { TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import store from "@/store/store";

import { chain, cloneDeep } from 'lodash';
import Component from "vue-class-component";
import Vue from "vue";

//new structures
import MessageReplyTypeNew from './new/MessageReplyType'
import MessageTypeNew from './new/MessageType'
import CallerTypeNew from './new/CallerType'
import WorkDatesNew from './new/WorkDates'
import WorkMonthDaysNew from './new/WorkMonthDays'
import WorkDaysNew from './new/WorkDays'
import WorkTimeNew from './new/WorkTime'
import WorkWeekDaysNew from './new/WorkWeekDays'
import ThreadIdNew from "./new/ThreadId";
import RegexpConditionsWrapperNew from './new/RegexpConditionsWrapper';
import XpConditionsWrapperNew from "./new/XpConditionsWrapper";
import MessageCountConditionsWrapperNew from './new/MessageCountConditionsWrapper'
import MessageTextConditionsWrapperNew from "./new/MessageTextConditionsWrapper";
import MessageTextLenConditionsWrapperNew from "./new/MessageTextLenConditionsWrapper";
import MessageWordConditionsWrapperNew from "./new/MessageWordConditionsWrapper";
import UserLoginConditionsWrapperNew from './new/UserLoginConditionsWrapper'
import UserNameConditionsWrapperNew from './new/UserNameConditionsWrapper'
import RankConditionsWrapperNew from "./new/RankConditionsWrapper";
import ActionPointsConditionsWrapperNew from "./new/ActionPointsConditionsWrapper";
import ReputationConditionsWrapperNew from "./new/ReputationConditionsWrapper";
import FirstUserMessageTimeConditionsWrapperNew from "./new/FirstUserMessageTimeConditionsWrapper";
import FirstUserEnterTimeConditionsWrapperNew from "./new/FirstUserEnterTimeConditionsWrapper";
import LastUserEnterTimeConditionsWrapperNew from "./new/LastUserEnterTimeConditionsWrapper";
import FirstUserActivityConditionsWrapperNew from "./new/FirstUserActivityConditionsWrapper";
import CheckBindedChannelNew from "./new/CheckBindedChannelWrapper/conditions/CheckBindedChannel";
import CheckBoostNew from "./new/CheckBoostWrapper/conditions/CheckBoost";
import CheckPremiumNew from "./new/CheckPremiumWrapper/conditions/CheckPremium";
import UserInformationNew from "./new/UserInformationConditionsWrapper/conditions/UserInformation";
import MessageTextEndWithNew from "./new/MessageTextConditionsWrapper/conditions/MessageTextEndWith";
import MessageTextFullMatchNew from "./new/MessageTextConditionsWrapper/conditions/MessageTextFullMatch";
import MessageTextStartWithNew from "./new/MessageTextConditionsWrapper/conditions/MessageTextStartWith";
import MessageTextRegExpNew from "./new/RegexpConditionsWrapper/conditions/MessageTextRegExp";
import MessageMentionEntityTypeNew from "./new/RegexpConditionsWrapper/conditions/MessageMentionEntityType";
import UserLoginRegExpNew from "./new/RegexpConditionsWrapper/conditions/UserLoginRegExp";
import UserNameRegExpNew from "./new/RegexpConditionsWrapper/conditions/UserNameRegExp";
import MessagePhoneNumberEntityTypeNew from "./new/MessagePhoneNumberEntityType";
import MessageTextMentionEntityTypeNew from "./new/RegexpConditionsWrapper/conditions/MessageTextMentionEntityType";
import XpEqualsNew from "./new/XpConditionsWrapper/conditions/XpEquals";
import XpLowerNew from "./new/XpConditionsWrapper/conditions/XpLower";
import XpUpperNew from "./new/XpConditionsWrapper/conditions/XpUpper";
import MessageCountLowerNew from "./new/MessageCountConditionsWrapper/conditions/MessageCountLower";
import MessageCountUpperNew from "./new/MessageCountConditionsWrapper/conditions/MessageCountUpper";
import DaysWithMessagesConditionsWrapperNew from "./new/DaysWithMessagesConditionsWrapper";
import DaysWithMessagesLowerNew from "./new/DaysWithMessagesConditionsWrapper/conditions/DaysWithMessagesLower";
import DaysWithMessagesUpperNew from "./new/DaysWithMessagesConditionsWrapper/conditions/DaysWithMessagesUpper";
import MessageTextMaxLenNew from "./new/MessageTextLenConditionsWrapper/conditions/MessageTextMaxLen";
import MessageTextMinLenNew from "./new/MessageTextLenConditionsWrapper/conditions/MessageTextMinLen";
import MessageWordFullMatchNew from "./new/MessageWordConditionsWrapper/conditions/MessageWordFullMatch";
import MessageWordEndWithNew from "./new/MessageWordConditionsWrapper/conditions/MessageWordEndWith";
import MessageWordStartWithNew from "./new/MessageWordConditionsWrapper/conditions/MessageWordStartWith";
import UserLoginMinLenNew from "./new/UserLoginConditionsWrapper/conditions/UserLoginMinLen";
import UserLoginMaxLenNew from "./new/UserLoginConditionsWrapper/conditions/UserLoginMaxLen";
import UserNameMinLenNew from "./new/UserNameConditionsWrapper/conditions/UserNameMinLen";
import UserNameMaxLenNew from "./new/UserNameConditionsWrapper/conditions/UserNameMaxLen";
import RankEqualsNew from "./new/RankConditionsWrapper/conditions/RankEquals";
import RankLowerNew from "./new/RankConditionsWrapper/conditions/RankLower";
import RankUpperNew from "./new/RankConditionsWrapper/conditions/RankUpper";
import ActionPointsEqualsNew from "./new/ActionPointsConditionsWrapper/conditions/ActionPointsEquals";
import ActionPointsLowerNew from "./new/ActionPointsConditionsWrapper/conditions/ActionPointsLower";
import ActionPointsUpperNew from "./new/ActionPointsConditionsWrapper/conditions/ActionPointsUpper";
import FirstUserMessageTimeMoreThatNew  from "./new/FirstUserMessageTimeConditionsWrapper/conditions/FirstUserMessageTimeMoreThat";
import FirstUserMessageTimeLowerThatNew from "./new/FirstUserMessageTimeConditionsWrapper/conditions/FirstUserMessageTimeLowerThat";
import FirstUserEnterTimeLowerThatNew from "./new/FirstUserEnterTimeConditionsWrapper/conditions/FirstUserEnterTimeLowerThat";
import FirstUserEnterTimeMoreThatNew from "./new/FirstUserEnterTimeConditionsWrapper/conditions/FirstUserEnterTimeMoreThat";
import LastUserEnterTimeLowerThatNew from "./new/LastUserEnterTimeConditionsWrapper/conditions/LastUserEnterTimeLowerThat";
import LastUserEnterTimeMoreThatNew from "./new/LastUserEnterTimeConditionsWrapper/conditions/LastUserEnterTimeMoreThat";
import FirstUserActivityLowerThatNew from "./new/FirstUserActivityConditionsWrapper/conditions/FirstUserActivityLowerThat";
import FirstUserActivityMoreThatNew from "./new/FirstUserActivityConditionsWrapper/conditions/FirstUserActivityMoreThat";
import ReputationEqualsNew from "./new/ReputationConditionsWrapper/conditions/ReputationEquals";
import ReputationUpperNew from "./new/ReputationConditionsWrapper/conditions/ReputationUpper";
import ReputationLowerNew from "./new/ReputationConditionsWrapper/conditions/ReputationLower";
import ReactionsConditionsWrapper from "./new/ReactionsConditionsWrapper";
import ReactionAdd from "./new/ReactionsConditionsWrapper/conditions/ReactionAdd";
import ReactionRemove from "./new/ReactionsConditionsWrapper/conditions/ReactionRemove";
import ReactionCount from "./new/ReactionsConditionsWrapper/conditions/ReactionCount";
import RequestUserType from "./new/RequestUserType";
import CheckRegexpPlaceholder from "./new/CheckRegexpPlaceholder";
import MessageTextSubstringMatchNew from "./new/MessageTextSubstringMatch";
import HasDialog from "./new/HasDialog";
import MessageLang from "./new/MessageLang";
import MessageToxic from "./new/MessageToxic";
import HasAchievement from "./new/HasAchievement";
import MessageForwardType from "./new/MessageForwardType";
import HasSimilarMessage from "./new/HasSimilarMessage";
import HasCaptchaQuest from "./new/HasCaptchaQuest";
import RefConditionsWrapper from "./new/RefConditionsWrapper";
import RefEquals from "./new/RefConditionsWrapper/conditions/RefEquals";
import RefLower from "./new/RefConditionsWrapper/conditions/RefLower";
import RefUpper from "./new/RefConditionsWrapper/conditions/RefUpper";

// old structures
import MessageReplyType from './MessageReplyType'
import MessageType from './MessageType'
import CallerType from './CallerType'
import ThreadId from "./ThreadId";
import CheckReplyBindedChannel from "./CheckBindedChannelWrapper/conditions/CheckReplyBindedChannel";
import ReplyMessageTextRegExp from "./RegexpConditionsWrapper/conditions/ReplyMessageTextRegExp";
import ReplyUserLoginRegExp from "./RegexpConditionsWrapper/conditions/ReplyUserLoginRegExp";
import ReplyUserNameRegExp from "./RegexpConditionsWrapper/conditions/ReplyUserNameRegExp";
import ReplyXpEquals from "./XpConditionsWrapper/conditions/ReplyXpEquals";
import ReplyXpLower from "./XpConditionsWrapper/conditions/ReplyXpLower";
import ReplyXpUpper from "./XpConditionsWrapper/conditions/ReplyXpUpper";
import ReplyMessageCountLower from "./MessageCountConditionsWrapper/conditions/ReplyMessageCountLower";
import ReplyMessageCountUpper from "./MessageCountConditionsWrapper/conditions/ReplyMessageCountUpper";
import ReplyMessageTextMinLen from "./MessageTextLenConditionsWrapper/conditions/ReplyMessageTextMinLen";
import ReplyMessageTextMaxLen from "./MessageTextLenConditionsWrapper/conditions/ReplyMessageTextMaxLen";
import ReplyUserInformation from "./UserInformationConditionsWrapper/conditions/ReplyUserInformation";
import CheckReplyBoost from "./CheckBoostWrapper/conditions/CheckReplyBoost";
import CheckReplyPremium from "./CheckPremiumWrapper/conditions/CheckReplyPremium";
import CheckBindedChannel from "./CheckBindedChannelWrapper/conditions/CheckBindedChannel";
import CheckBoost from "./CheckBoostWrapper/conditions/CheckBoost";
import CheckPremium from "./CheckPremiumWrapper/conditions/CheckPremium";
import UserInformation from "./UserInformationConditionsWrapper/conditions/UserInformation";
import MessageTextFullMatch from "./MessageTextConditionsWrapper/conditions/MessageTextFullMatch";
import ReplyActionPointsEquals from "./ReplyActionPointsConditionsWrapper/conditions/ReplyActionPointsEquals";
import ReplyActionPointsUpper from "./ReplyActionPointsConditionsWrapper/conditions/ReplyActionPointsUpper";
import ReplyActionPointsLower from "./ReplyActionPointsConditionsWrapper/conditions/ReplyActionPointsLower";
import ReplyReputationEquals from "./ReplyReputationConditionsWrapper/conditions/ReplyReputationEquals";
import ReplyReputationUpper from "./ReplyReputationConditionsWrapper/conditions/ReplyReputationUpper";
import ReplyReputationLower from "./ReplyReputationConditionsWrapper/conditions/ReplyReputationLower";
import ReplyMessageTextFullMatch from "./ReplyMessageTextConditionsWrapper/conditions/ReplyMessageTextFullMatch";
import ReplyMessageTextStartWith from "./ReplyMessageTextConditionsWrapper/conditions/ReplyMessageTextStartWith";
import ReplyMessageTextEndWith from "./ReplyMessageTextConditionsWrapper/conditions/ReplyMessageTextEndWith";
import ReplyMessageWordFullMatch from "./ReplyMessageWordConditionsWrapper/conditions/ReplyMessageWordFullMatch";
import ReplyMessageWordStartWith from "./ReplyMessageWordConditionsWrapper/conditions/ReplyMessageWordStartWith";
import ReplyMessageWordEndWith from "./ReplyMessageWordConditionsWrapper/conditions/ReplyMessageWordEndWith";
import MessageTextEndWith from "./MessageTextConditionsWrapper/conditions/MessageTextEndWith";
import MessageTextStartWith from "./MessageTextConditionsWrapper/conditions/MessageTextStartWith";
import FirstUserActivityLowerThat from "./FirstUserActivityConditionsWrapper/conditions/FirstUserActivityLowerThat";
import FirstUserActivityMoreThat from "./FirstUserActivityConditionsWrapper/conditions/FirstUserActivityMoreThat";
import LastUserEnterTimeMoreThat from "./LastUserEnterTimeConditionsWrapper/conditions/LastUserEnterTimeMoreThat";
import LastUserEnterTimeLowerThat from "./LastUserEnterTimeConditionsWrapper/conditions/LastUserEnterTimeLowerThat";
import MessageTextRegExp from "./RegexpConditionsWrapper/conditions/MessageTextRegExp";
import MessageMentionEntityType from "./RegexpConditionsWrapper/conditions/MessageMentionEntityType";
import UserLoginRegExp from "./RegexpConditionsWrapper/conditions/UserLoginRegExp";
import UserNameRegExp from "./RegexpConditionsWrapper/conditions/UserNameRegExp";
import MessagePhoneNumberEntityType from "./MessagePhoneNumberEntityType";
import MessageTextMentionEntityType from "./RegexpConditionsWrapper/conditions/MessageTextMentionEntityType";
import XpEquals from "./XpConditionsWrapper/conditions/XpEquals";
import XpLower from "./XpConditionsWrapper/conditions/XpLower";
import XpUpper from "./XpConditionsWrapper/conditions/XpUpper";
import MessageCountLower from "./MessageCountConditionsWrapper/conditions/MessageCountLower";
import MessageCountUpper from "./MessageCountConditionsWrapper/conditions/MessageCountUpper";
import MessageTextMaxLen from "./MessageTextLenConditionsWrapper/conditions/MessageTextMaxLen";
import DaysWithMessagesUpper from "./DaysWithMessagesConditionsWrapper/conditions/DaysWithMessagesUpper";
import DaysWithMessagesLower from "./DaysWithMessagesConditionsWrapper/conditions/DaysWithMessagesLower";
import MessageTextMinLen from "./MessageTextLenConditionsWrapper/conditions/MessageTextMinLen";
import MessageWordFullMatch from "./MessageWordConditionsWrapper/conditions/MessageWordFullMatch";
import MessageWordEndWith from "./MessageWordConditionsWrapper/conditions/MessageWordEndWith";
import MessageWordStartWith from "./MessageWordConditionsWrapper/conditions/MessageWordStartWith";
import UserLoginMinLen from "./UserLoginConditionsWrapper/conditions/UserLoginMinLen";
import UserLoginMaxLen from "./UserLoginConditionsWrapper/conditions/UserLoginMaxLen";
import UserNameMinLen from "./UserNameConditionsWrapper/conditions/UserNameMinLen";
import UserNameMaxLen from "./UserNameConditionsWrapper/conditions/UserNameMaxLen";
import RankEquals from "./RankConditionsWrapper/conditions/RankEquals";
import RankLower from "./RankConditionsWrapper/conditions/RankLower";
import RankUpper from "./RankConditionsWrapper/conditions/RankUpper";
import ActionPointsEquals from "./ActionPointsConditionsWrapper/conditions/ActionPointsEquals";
import ActionPointsLower from "./ActionPointsConditionsWrapper/conditions/ActionPointsLower";
import ActionPointsUpper from "./ActionPointsConditionsWrapper/conditions/ActionPointsUpper";
import FirstUserMessageTimeMoreThat from "./FirstUserMessageTimeConditionsWrapper/conditions/FirstUserMessageTimeMoreThat";
import FirstUserMessageTimeLowerThat from "./FirstUserMessageTimeConditionsWrapper/conditions/FirstUserMessageTimeLowerThat";
import FirstUserEnterTimeLowerThat from "./FirstUserEnterTimeConditionsWrapper/conditions/FirstUserEnterTimeLowerThat";
import FirstUserEnterTimeMoreThat from "./FirstUserEnterTimeConditionsWrapper/conditions/FirstUserEnterTimeMoreThat";
import ReplyMessageTextSubstringMatch from "./ReplyMessageTextSubstringMatch";
import ReputationEquals from "./ReputationConditionsWrapper/conditions/ReputationEquals";
import ReputationUpper from "./ReputationConditionsWrapper/conditions/ReputationUpper";
import ReputationLower from "./ReputationConditionsWrapper/conditions/ReputationLower";
import WorkDates from "./WorkDates";
import WorkMonthDays from "./WorkMonthDays";
import WorkDays from "./WorkDays";
import WorkTime from "./WorkTime";
import WorkWeekDays from "./WorkWeekDays";
import ReplyDaysWithMessagesLower from "./DaysWithMessagesConditionsWrapper/conditions/ReplyDaysWithMessagesLower";
import ReplyDaysWithMessagesUpper from "./DaysWithMessagesConditionsWrapper/conditions/ReplyDaysWithMessagesUpper";
import MessageTextSubstringMatch from "./MessageTextSubstringMatch";

@Component
export default class ConditionHelper extends Vue {

  conditions(conditionVersion: TriggerCondition['version']): Record<string, EntityData> {
    if (conditionVersion === 'v1') {
      return chain([
        MessageTextEndWith,
        MessageTextFullMatch,
        MessageTextStartWith,

        MessageTextRegExp,
        MessageMentionEntityType,
        UserLoginRegExp,
        UserNameRegExp,
        MessagePhoneNumberEntityType,
        MessageTextMentionEntityType,

        XpEquals,
        XpLower,
        XpUpper,

        MessageCountLower,
        MessageCountUpper,

        DaysWithMessagesLower,
        DaysWithMessagesUpper,

        MessageTextMaxLen,
        MessageTextMinLen,

        MessageWordFullMatch,
        MessageWordEndWith,
        MessageWordStartWith,

        UserLoginMinLen,
        UserLoginMaxLen,

        UserNameMinLen,
        UserNameMaxLen,

        RankEquals,
        RankLower,
        RankUpper,

        ActionPointsEquals,
        ActionPointsLower,
        ActionPointsUpper,

        FirstUserMessageTimeMoreThat,
        FirstUserMessageTimeLowerThat,
        FirstUserEnterTimeLowerThat,
        FirstUserEnterTimeMoreThat,

        LastUserEnterTimeLowerThat,
        LastUserEnterTimeMoreThat,

        FirstUserActivityLowerThat,
        FirstUserActivityMoreThat,

        MessageType,

        WorkDates,
        WorkMonthDays,
        WorkDays,
        WorkTime,
        WorkWeekDays,

        UserInformation,

        ThreadId,
        CallerType,
        MessageReplyType,
        CheckBindedChannel,
        CheckBoost,
        CheckPremium,

        ReputationEquals,
        ReputationUpper,
        ReputationLower,

        MessageTextSubstringMatch,

        // reply deprecated conditions
        ReplyMessageTextRegExp,

        ReplyUserLoginRegExp,

        ReplyUserNameRegExp,

        ReplyXpEquals,
        ReplyXpLower,
        ReplyXpUpper,

        ReplyMessageCountLower,
        ReplyMessageCountUpper,
        ReplyMessageTextMinLen,
        ReplyMessageTextMaxLen,

        ReplyActionPointsEquals,
        ReplyActionPointsUpper,
        ReplyActionPointsLower,

        ReplyReputationEquals,
        ReplyReputationUpper,
        ReplyReputationLower,

        ReplyMessageTextFullMatch,
        ReplyMessageTextStartWith,
        ReplyMessageTextEndWith,
        ReplyMessageTextSubstringMatch,

        ReplyMessageWordFullMatch,
        ReplyMessageWordStartWith,
        ReplyMessageWordEndWith,

        ReplyUserInformation,

        CheckReplyBindedChannel,
        CheckReplyBoost,
        CheckReplyPremium,

        ReplyDaysWithMessagesLower,
        ReplyDaysWithMessagesUpper,
      ])
          .keyBy('structure.type')
          .value();
    }

    return cloneDeep(chain([
          MessageTextEndWithNew,
          MessageTextFullMatchNew,
          MessageTextStartWithNew,
          MessageTextSubstringMatchNew,

          MessageTextRegExpNew,
          MessageMentionEntityTypeNew,
          UserLoginRegExpNew,
          UserNameRegExpNew,
          MessagePhoneNumberEntityTypeNew,
          MessageTextMentionEntityTypeNew,

          XpEqualsNew,
          XpLowerNew,
          XpUpperNew,

          MessageCountLowerNew,
          MessageCountUpperNew,

          DaysWithMessagesLowerNew,
          DaysWithMessagesUpperNew,

          MessageTextMaxLenNew,
          MessageTextMinLenNew,

          MessageWordFullMatchNew,
          MessageWordEndWithNew,
          MessageWordStartWithNew,

          UserLoginMinLenNew,
          UserLoginMaxLenNew,

          UserNameMinLenNew,
          UserNameMaxLenNew,

          RankEqualsNew,
          RankLowerNew,
          RankUpperNew,

          ReputationEqualsNew,
          ReputationUpperNew,
          ReputationLowerNew,

          ActionPointsEqualsNew,
          ActionPointsLowerNew,
          ActionPointsUpperNew,

          WorkDatesNew,
          WorkMonthDaysNew,
          WorkDaysNew,
          WorkTimeNew,
          WorkWeekDaysNew,

          FirstUserMessageTimeMoreThatNew,
          FirstUserMessageTimeLowerThatNew,

          FirstUserEnterTimeLowerThatNew,
          FirstUserEnterTimeMoreThatNew,

          LastUserEnterTimeLowerThatNew,
          LastUserEnterTimeMoreThatNew,

          FirstUserActivityLowerThatNew,
          FirstUserActivityMoreThatNew,

          MessageTypeNew,

          UserInformationNew,

          ThreadIdNew,
          CallerTypeNew,
          MessageReplyTypeNew,
          CheckBindedChannelNew,
          CheckBoostNew,
          CheckPremiumNew,

          ReactionRemove,
          ReactionCount,
          ReactionAdd,

          RequestUserType,

          CheckRegexpPlaceholder,

          HasDialog,

          MessageLang,
          MessageToxic,

          HasAchievement,
          HasSimilarMessage,
          HasCaptchaQuest,

          MessageForwardType,

          RefEquals,
          RefLower,
          RefUpper,
        ]
    ))
        .keyBy('structure.condition_type')
        .value();
  }
}

export const newConditionWrappers = [
  MessageTextConditionsWrapperNew,
  RegexpConditionsWrapperNew,
  XpConditionsWrapperNew,
  MessageCountConditionsWrapperNew,
  DaysWithMessagesConditionsWrapperNew,
  MessageTextLenConditionsWrapperNew,
  MessageWordConditionsWrapperNew,
  UserLoginConditionsWrapperNew,
  UserNameConditionsWrapperNew,
  RankConditionsWrapperNew,
  ReputationConditionsWrapperNew,
  ActionPointsConditionsWrapperNew,
  FirstUserMessageTimeConditionsWrapperNew,
  FirstUserEnterTimeConditionsWrapperNew,
  LastUserEnterTimeConditionsWrapperNew,
  FirstUserActivityConditionsWrapperNew,
  ReactionsConditionsWrapper,
  RefConditionsWrapper
]

export const conditionsGroupsTypes: Record<string, Array<EntityData>> = {
  messageConditions: [
    MessageTextConditionsWrapperNew,
    MessageWordConditionsWrapperNew,
    MessageLang,
    MessageToxic,
    ...store.getters.isChatSet && store.state.chatState!.chat!.version >= 500 ? [
        HasSimilarMessage
    ] : []
  ],
  messagePropertiesConditions: [
    MessageTypeNew,
    ...store.getters.isChatSet && store.state.chatState.chat!.version >= 6000 ? [
      MessageForwardType,
    ] : [],
    MessageTextLenConditionsWrapperNew,
  ],
  userConditions: [
    UserLoginConditionsWrapperNew,
    UserNameConditionsWrapperNew,
    UserInformationNew,
    HasDialog,
    ...store.getters.isChatSet && store.state.chatState.chat!.version >= 6000 ? [
        RefConditionsWrapper,
    ] : [],
  ],
  userRankAndPointsConditions: [
    RankConditionsWrapperNew,
    ActionPointsConditionsWrapperNew,
    ReputationConditionsWrapperNew,
    XpConditionsWrapperNew,
    HasAchievement
  ],
  reactionConditions: [
    ReactionsConditionsWrapper,
    ReactionCount
  ],
  dataConditions: [
    WorkDaysNew,
    WorkWeekDaysNew,
    WorkDatesNew,
    WorkMonthDaysNew,
    WorkTimeNew,
  ],
  userActionsConditions: [
    MessageCountConditionsWrapperNew,
    DaysWithMessagesConditionsWrapperNew,
    FirstUserEnterTimeConditionsWrapperNew,
    LastUserEnterTimeConditionsWrapperNew,
    FirstUserActivityConditionsWrapperNew,
    FirstUserMessageTimeConditionsWrapperNew
  ],
  otherConditions: [
    RegexpConditionsWrapperNew,
    CheckRegexpPlaceholder,
    ThreadIdNew,
    CallerTypeNew,
    RequestUserType,
    MessageReplyTypeNew,
    CheckBindedChannelNew,
    CheckBoostNew,
    CheckPremiumNew,
    HasCaptchaQuest
  ]
}
