















































import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import TriggerActionBindTarget from "@/components/TriggerSetup/components/TriggerActionBindTarget.vue";

import { Component, Mixins } from 'vue-property-decorator'
import { SelectOption } from "piramis-base-components/src/logic/types";
import { DeleteMessageDelayEnum } from "./types";
import ActionTargets from "../../components/ActionTargets/ActionTargets.vue";
import TimezoneSelect from "@/components/TimezoneSelect.vue";

@Component({
  'components': {
    TimezoneSelect,
    TriggerActionBindTarget,
    ActionTargets
  }
})
export default class ScheduleDeleteMessageActionSetupView extends Mixins(ActionSetupView) {
  get delayOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t('schedule_delete_message_action_delay_near').toString(),
        value: DeleteMessageDelayEnum.Near
      },
      {
        label: this.$t('schedule_delete_message_action_delay_next_first_day').toString(),
        value: DeleteMessageDelayEnum.NextFirstDay
      },
      {
        label: this.$t('schedule_delete_message_action_delay_next_first_or_second_day').toString(),
        value: DeleteMessageDelayEnum.NextFirstOrSecondDay
      },
    ]
  }
}
