























































import { TriggerControlsMixin } from '@/components/TriggerSetup/logic/mixins/trigger-controls.mixin'
import { RawTriggerData } from '@/components/TriggerSetup/logic/types/types'
import { TriggerFactory } from '@/components/TriggerSetup/logic/factories/trigger-factory'

import { Component, Emit, Prop } from 'vue-property-decorator'

@Component
export default class TriggerControls extends TriggerControlsMixin {
  triggerFactory: TriggerFactory = new TriggerFactory()

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  getRawTrigger(): RawTriggerData {
    return this.triggerFactory.triggerDataToRawTriggerData(this.triggerData)
  }

  @Emit()
  remove(): RawTriggerData {
    return this.getRawTrigger()
  }

  @Emit()
  save(): RawTriggerData {
    return this.getRawTrigger()
  }

  @Emit()
  saveNoMove(): RawTriggerData {
    return this.getRawTrigger()
  }

  @Emit()
  exportTrigger() {
    return null
  }
}
