import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

export type BaseTriggerCondition = {
  value      : Array<any>
  filters    : Array<string>
  caller     : string
  messageType: string
  invert     : boolean
}

export type OldTriggerCondition = BaseTriggerCondition & {
  type       : ConditionsType
  version    : 'v1'
}

export type NewTriggerCondition = BaseTriggerCondition & {
  condition_type       : ConditionsType
  placeholder   : null | string
  condition_zone: ConditionZone | null
  version       : 'v2'
}

export const enum ConditionZone {
  Common = 'Common',
  Message = 'Message',
  ReplyMessage = 'ReplyMessage',
  RequestActor = 'RequestActor',
  RequestMessage = 'RequestMessage',
  ReactionActor = 'ReactionActor',
  ReactionMessage = 'ReactionMessage',
}

export type TriggerCondition = OldTriggerCondition | NewTriggerCondition

export type TriggerAction = {
  type       : string
  value      : Array<any>
  filters    : Array<string>
  caller     : Array<string>
  messageType: string
}

export type TriggerActionGroup = {
  from    : number
  to      : number
  chance  : number
  actions : Array<IterableListItem<EntityData>>
}

export type BaseTriggerSettings = {
  id                            ?: string,
  owner                         ?: string,
  owner_id                      ?: string,
  source_type                   ?: 'Network' | 'Group',
  type                           : string
  name                           : string
  shared                         : boolean
  chat_id                        : number | null
  actionExecuteType              : string
  chance                         : number
  delay                          : number
  limit                          : number
  limitInterval                  : number
  globalLimit                    : number
  globalLimitInterval            : number
  warnLimit                      : number
  warnLimitInterval              : number
  conditionCheckInterval         : number
  processEditedMessages          : boolean
  processEditedMessageOnes       : boolean
  continueProcessAfterSuccessEdit: boolean
  roundLimitInterval             : boolean
  roundGlobalLimitInterval       : boolean
  roundWarnLimitInterval         : boolean
  transparent			               : boolean
  processAlbumsLikeFirstPhoto	   : boolean
  form                           : string
  multiplyForm                   : boolean
}

export type RawTriggerData = BaseTriggerSettings & {
  conditions                     : Array<Array<TriggerCondition>>
  actions                        : Array<any>
  conditionSuccessActions        : Array<any>
  conditionFailActions           : Array<any>
  limitActions                   : Array<any>
  globalLimitActions             : Array<any>
  warnActions                    : Array<any>
  formActions                    : Array<any>
}

export type IterableListItem<T> = { guid: string, value: T }

export type TriggerData = BaseTriggerSettings & {
  conditions              : Array<IterableListItem<Array<IterableListItem<EntityData>>>>
  actions                 : Array<IterableListItem<TriggerActionGroup>>
  conditionSuccessActions : Array<IterableListItem<any>>
  conditionFailActions    : Array<IterableListItem<any>>
  limitActions            : Array<IterableListItem<any>>
  globalLimitActions      : Array<IterableListItem<any>>
  warnActions             : Array<IterableListItem<any>>
  formActions             : Array<IterableListItem<any>>
}

export const enum ActionsWrapperType {
  WarnActionsWrapper = 'WarnActionsWrapper',
  AchievementsActionsWrapper = 'AchievementsActionsWrapper'
}

export const enum ActionsType {
  SendFormToChat = 'SendFormToChat',
  SendFormToPrivate = 'SendFormToPrivate',
  SendFormResultToTarget = 'SendFormResultToTarget',
  SendMessageAction = 'SendMessageAction',
  DeleteMessageAction = 'DeleteMessageAction',
  SendPrivateMessageAction = 'SendPrivateMessageAction',
  SendMessageToTargetAction = 'SendMessageToTargetAction',
  ForwardReplyMessageToTargetAction = 'ForwardReplyMessageToTargetAction',
  ForwardMessageToTargetAction = 'ForwardMessageToTargetAction',
  SendMessageToCalculatedTargetAction = 'SendMessageToCalculatedTargetAction',
  KickAction = 'KickAction',
  BanAction = 'BanAction',
  UnbanAction = 'UnbanAction',
  MuteAction = 'MuteAction',
  UnmuteAction = 'UnmuteAction',
  ChangeReputationIntervalAction = 'ChangeReputationIntervalAction',
  ChangePointsIntervalAction = 'ChangePointsIntervalAction',
  ChangeXpIntervalAction = 'ChangeXpIntervalAction',
  SetPointsAction = 'SetPointsAction',
  SetReputationAction = 'SetReputationAction',
  CalculatePropertyTriggerAction = 'CalculatePropertyTriggerAction',
  ChangeTriggerActiveStateAction = 'ChangeTriggerActiveStateAction',
  TriggerWarnAction = 'TriggerWarnAction',
  WarnAction = 'WarnAction',
  SilentWarnAction = 'SilentWarnAction',
  PinMessageAction = 'PinMessageAction',
  UnPinMessageAction = 'UnPinMessageAction',
  AddMessageReactionAction = 'AddMessageReactionAction',
  // achievements
  AddAchievementTriggerAction = 'AddAchievementTriggerAction',
  DeleteAchievementTriggerAction = 'DeleteAchievementTriggerAction',
  //
  CompleteCaptchaQuestAction = 'CompleteCaptchaQuestAction',
  DeleteAllMessageAction = 'DeleteAllMessageAction',
  ScheduleDeleteMessageAction = 'ScheduleDeleteMessageAction',

  //deprecated types
  ChangePointsAction = 'ChangePointsAction',
  ChangeReputationAction = 'ChangeReputationAction'
}

export const enum ConditionsWrapperType {
  XpConditionsWrapper = 'XpConditionsWrapper',
  RegexpConditionsWrapper = 'RegexpConditionsWrapper',
  MessageTextLenConditionsWrapper = 'MessageTextLenConditionsWrapper',
  MessageTextConditionsWrapper = 'MessageTextConditionsWrapper',
  // ReplyMessageTextConditionsWrapper = 'ReplyMessageTextConditionsWrapper',
  // ReplyMessageWordConditionsWrapper = 'ReplyMessageWordConditionsWrapper',
  MessageCountConditionsWrapper = 'MessageCountConditionsWrapper',
  MessageWordConditionsWrapper = 'MessageWordConditionsWrapper',
  DaysWithMessagesConditionsWrapper = 'DaysWithMessagesConditionsWrapper',
  UserNameConditionsWrapper = 'UserNameConditionsWrapper',
  UserLoginConditionsWrapper = 'UserLoginConditionsWrapper',
  RankConditionsWrapper = 'RankConditionsWrapper',
  ActionPointsConditionsWrapper = 'ActionPointsConditionsWrapper',
  // ReplyActionPointsConditionsWrapper = 'ReplyActionPointsConditionsWrapper',
  ReputationConditionsWrapper = 'ReputationConditionsWrapper',
  // ReplyReputationConditionsWrapper = 'ReplyReputationConditionsWrapper',
  FirstUserMessageTimeConditionsWrapper = 'FirstUserMessageTimeConditionsWrapper',
  FirstUserEnterTimeConditionsWrapper = 'FirstUserEnterTimeConditionsWrapper',
  LastUserEnterTimeConditionsWrapper = 'LastUserEnterTimeConditionsWrapper',
  FirstUserActivityConditionsWrapper = 'FirstUserActivityConditionsWrapper',
  // UserInformationWrapper = 'UserInformationWrapper',
  // CheckBindedChannelWrapper = 'CheckBindedChannelWrapper',
  //
  CheckBoostWrapper = 'CheckBoostWrapper',
  CheckPremiumWrapper = 'CheckPremiumWrapper',
  ReactionsConditions = 'ReactionsConditions',
  RefConditionsWrapper = 'RefConditionsWrapper',
}

export const enum ConditionsType {
  MessageTextFullMatch = 'MessageTextFullMatch',
  MessageTextStartWith = 'MessageTextStartWith',
  MessageTextEndWith = 'MessageTextEndWith',
  MessageTextSubstringMatch = 'MessageTextSubstringMatch',
  MessageType = 'MessageType',
  MessageWordStartWith = 'MessageWordStartWith',
  MessageWordEndWith = 'MessageWordEndWith',
  MessageWordFullMatch = 'MessageWordFullMatch',
  UserLoginMaxLen = 'UserLoginMaxLen',
  UserLoginMinLen = 'UserLoginMinLen',
  UserLang = 'UserLang',
  UserNameMinLen = 'UserNameMinLen',
  UserNameMaxLen = 'UserNameMaxLen',
  RankEquals = 'RankEquals',
  RankLower = 'RankLower',
  RankUpper = 'RankUpper',
  ActionPointsEquals = 'ActionPointsEquals',
  ActionPointsLower = 'ActionPointsLower',
  ActionPointsUpper = 'ActionPointsUpper',
  ReputationEquals = 'ReputationEquals',
  ReputationLower = 'ReputationLower',
  ReputationUpper = 'ReputationUpper',
  WorkDays = 'WorkDays',
  WorkWeekDays = 'WorkWeekDays',
  WorkDates = 'WorkDates',
  WorkMonthDays = 'WorkMonthDays',
  WorkTime = 'WorkTime',
  FirstUserMessageTimeMoreThat = 'FirstUserMessageTimeMoreThat',
  FirstUserMessageTimeLowerThat = 'FirstUserMessageTimeLowerThat',
  FirstUserEnterTimeMoreThat = 'FirstUserEnterTimeMoreThat',
  FirstUserEnterTimeLowerThat = 'FirstUserEnterTimeLowerThat',
  LastUserEnterTimeMoreThat = 'LastUserEnterTimeMoreThat',
  LastUserEnterTimeLowerThat = 'LastUserEnterTimeLowerThat',
  FirstUserActivityMoreThat = 'FirstUserActivityMoreThat',
  FirstUserActivityLowerThat = 'FirstUserActivityLowerThat',
  CallerType = 'CallerType',
  ThreadId = 'ThreadId',
  MessageReplyType = 'MessageReplyType',
  MessageTextRegExp = 'MessageTextRegExp',
  MessageTextMentionEntityType = 'MessageTextMentionEntityType',
  MessageMentionEntityType = 'MessageMentionEntityType',
  UserLoginRegExp = 'UserLoginRegExp',
  UserNameRegExp = 'UserNameRegExp',
  MessagePhoneNumberEntityType = 'MessagePhoneNumberEntityType',
  XpEquals = 'XpEquals',
  XpLower = 'XpLower',
  XpUpper = 'XpUpper',
  DaysWithMessagesLower = 'DaysWithMessagesLower',
  DaysWithMessagesUpper = 'DaysWithMessagesUpper',
  MessageTextMinLen = 'MessageTextMinLen',
  MessageTextMaxLen = 'MessageTextMaxLen',
  UserInformation = 'UserInformation',

  CheckBindedChannel = 'CheckBindedChannel',

  CheckBoost = 'CheckBoost',

  CheckPremium = 'CheckPremium',

  ReactionAdd = 'ReactionAdd',
  ReactionRemove = 'ReactionRemove',
  ReactionCount = 'ReactionCount',

  RequestUserType = 'RequestUserType',

  CheckRegexpPlaceholder = 'CheckRegexpPlaceholder',
  HasDialog = 'HasDialog',

  HasAchievement = 'HasAchievement',
  HasSimilarMessage = 'HasSimilarMessage',
  HasCaptchaQuest = 'HasCaptchaQuest',
  MessageForwardType = 'MessageForwardType',

  RefEquals = 'RefEquals',
  RefLower = 'RefLower',
  RefUpper = 'RefUpper',

  //deprecated
  ReplyActionPointsEquals = 'ReplyActionPointsEquals',
  ReplyActionPointsLower = 'ReplyActionPointsLower',
  ReplyActionPointsUpper = 'ReplyActionPointsUpper',
  ReplyReputationEquals = 'ReplyReputationEquals',
  ReplyReputationLower = 'ReplyReputationLower',
  ReplyReputationUpper = 'ReplyReputationUpper',
  ReplyMessageTextFullMatch = 'ReplyMessageTextFullMatch',
  ReplyMessageTextStartWith = 'ReplyMessageTextStartWith',
  ReplyMessageTextEndWith = 'ReplyMessageTextEndWith',
  ReplyMessageTextSubstringMatch = 'ReplyMessageTextSubstringMatch',
  ReplyMessageWordStartWith = 'ReplyMessageWordStartWith',
  ReplyMessageWordEndWith = 'ReplyMessageWordEndWith',
  ReplyMessageWordFullMatch = 'ReplyMessageWordFullMatch',
  ReplyMessageTextRegExp = 'ReplyMessageTextRegExp',
  ReplyUserLoginRegExp = 'ReplyUserLoginRegExp',
  ReplyUserNameRegExp = 'ReplyUserNameRegExp',
  ReplyXpEquals = 'ReplyXpEquals',
  ReplyXpLower = 'ReplyXpLower',
  ReplyXpUpper = 'ReplyXpUpper',
  MessageCountLower = 'MessageCountLower',
  MessageCountUpper = 'MessageCountUpper',
  ReplyMessageCountLower = 'ReplyMessageCountLower',
  ReplyMessageCountUpper = 'ReplyMessageCountUpper',
  ReplyDaysWithMessagesLower = 'ReplyDaysWithMessagesLower',
  ReplyDaysWithMessagesUpper = 'ReplyDaysWithMessagesUpper',
  ReplyMessageTextMinLen = 'ReplyMessageTextMinLen',
  ReplyMessageTextMaxLen = 'ReplyMessageTextMaxLen',
  ReplyUserInformation = 'ReplyUserInformation',
  CheckReplyPremium = 'CheckReplyPremium',
  CheckReplyBindedChannel = 'CheckReplyBindedChannel',
  CheckReplyBoost = 'CheckReplyBoost',

  MessageLang = 'MessageLang',
  MessageToxic = 'MessageToxic'

}

export type TriggerActionSettingGroups = 'form' | 'warn' | 'chatLimits' | 'userLimits' | 'advanced'
